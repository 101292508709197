import React from "react"
import { graphql } from "gatsby"
import IframeResizer from "iframe-resizer-react"

import SEO from "../components/SEO/SEO"
import Container from "../components/Container/Container"
import Title from "../components/Title/Title"
import Amenities from "../sections/booking/Amenities/Amenities"

const Booking = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicBooking.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />
      <Container>
        <div className="text-center">
          <Title preTitle>{pageData.page_pre_title.text}</Title>
        </div>
        <div className="mb-10 text-center lg:mb-12">
          <Title singlePage>{pageData.page_title.text}</Title>
        </div>
        <div>
          <IframeResizer
            src={pageData.embed_url.text}
            className="w-full min-w-full "
            frameBorder="0"
            scrolling="yes"
            id="cloudbeds"
          />
        </div>
        <section>
          <Amenities
            amenities_title={pageData.amenities_title_1}
            amenities_text={pageData.amenities_text_1}
            amenities={pageData.amenities_1}
          />
        </section>
        <section>
          <Amenities
            amenities_title={pageData.amenities_title_2}
            amenities_text={pageData.amenities_text_2}
            amenities={pageData.amenities_2}
          />
        </section>
      </Container>
    </>
  )
}

export default Booking

export const query = graphql`
  query {
    prismicBooking {
      data {
        page_pre_title {
          text
        }
        page_title {
          text
        }
        embed_url {
          text
        }

        amenities_title_1 {
          text
        }
        amenities_text_1 {
          html
        }

        amenities_1 {
          icon_image {
            gatsbyImageData(width: 48, layout: FIXED)
          }
          amenity_title {
            text
          }
        }

        amenities_title_2 {
          text
        }
        amenities_text_2 {
          html
        }

        amenities_2 {
          icon_image {
            gatsbyImageData(width: 48, layout: FIXED)
          }
          amenity_title {
            text
          }
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
